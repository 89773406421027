/*
	Stellar by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Header */

	body.is-preload #header.alt > * {
		opacity: 1;
	}

	body.is-preload #header.alt .logo {
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}